<template>
  <div id="episodeListingContainer">
    <div class="mainLayout" v-if="checkSeries && dataFetched">
      <div class="mainLayoutSeason">
        <select
          v-model="seasonNum"
          :class="[localDisplayLang === 'eng' ? 'select-eng' : 'select-ara']"
          @change="getSeasonContents"
        >
          <option v-for="(index, i) in seasonCount" :key="i" :value="index" id="season-option"
            >{{ $t("Season") }} {{ index }}</option
          >
        </select>
      </div>
      <div :class="[localDisplayLang === 'ara' ? 'episodeCardsAra' : 'episodeCards']">
        <div class="episodeCard" v-for="(episode, index) in episodes" :key="index" @click="playContent(episode, index)">
          <div class="episodeContainer">
            <div class="episodePosterLayout">
              <img :src="episode.poster[0].filelist[0].filename" class="episodePoster" alt="" />
            </div>
            <div v-if="isObjectTag(episode)" :class="[localDisplayLang === 'ara' ? 'episodeTagRight' : 'episodeTag']">
              <p class="tag">{{ pickTagForContent(episode) }}</p>
            </div>
            <img class="play-img" src="@/assets/icons/Ic_Play1.svg" alt="" />
            <div class="episodeContentLayout">
              <div class="episodeContentSplitter">
                <p class="episodeTitle">{{ episode.title }}</p>
                <p class="episodeDuration">
                  {{ calcTimeFormat(episode.duration) }}
                </p>
              </div>
              <p
                class="episodeDescription"
                :class="{ 'episodeDescription-ara' : localDisplayLang === 'ara' }"
                v-if="episode.shortdescription || episode.longdescription"
              >
                {{
                  episode.longdescription
                    ? episode.longdescription.length > 150
                      ? episode.longdescription.slice(0, 150)
                      : episode.longdescription
                    : episode.shortdescription.slice(0, 150)
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isloading">
      <Loading></Loading>
    </div>
  </div>
</template>

<script>
import { vLivePlaylistItem } from "/src/vue-player/vLiveWebPlayer.js";
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import PlaybackUtilities from "@/mixins/playbackUtilities.js";
import { store } from "@/store/store";
export default {
  props: {
    contentDetails: {
      type: Object,
    },

    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      availabilities: [],
      subscriptions: [],
      purchases: [],
      filteredAvailabilities: [],
      playbackInitiated: false,
      pricemodel: [],
      dataFetched: false,
      seasonNum: 1,
      seasonCount: null,
      localDisplayLang: null,
      seasonCountArray: [],
      isMpegRequired: false,
      episodes: [],
      isloading: true,
      totalEpisodeCount: 0,
      episodePlayFromRoute: false,
      episodePaginatinInProgress: false,
    };
  },

  watch: {
    seasonNum(val) {
      //console.log("Season number : ", val);
      console.log("Season number : ", this.seasonNum);
      //this.getAvailabilityForAllContents(this.episodes);
      // this.isloading=true
      // this.episodes=[]
      // this.totalEpisodeCount = 0;
      // if (val > 0) {

      // }
    },
    subscriberid(val) {
      if (val) {
        this.getAvailabilityForAllContents(this.episodes);
      }
    },
    subscriptionList(val) {
      console.log("subscriber list", val);
      if (val.length > 0) {
        this.subscriptions = val;
        this.getAvailabilityForAllContents(this.episodes);
      }
    },
    availabilityList(val) {
      if (Array.isArray(val) && val.length > 0) {
        this.availabilities = val;
        this.getAvailabilityForAllContents(this.episodes);
      }
    },

    episodes(val) {
      if (val) {
        this.getAvailabilityForAllContents(val);
      }

      if (val.length > 0) {
        this.getAudioAndSubtitleLang(val[0]);
      }
    },
  },
  computed: {
    ...mapGetters([
      "subscriberid",
      "availabilityList",
      "subscriptionList",
      "purchaseList",
      "appConfig",
      "getEpisodePaginationInProgress",
      "profileid",
    ]),
    checkSeries: function() {
      if (this.contentDetails.category == "MOVIE") {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.episodes = [];
    //assigning availability list response.

    this.isMpegRequired = this.checkMpegRequired();
    // console.log('player instance is in CREATED------------',this.playerInstance)
    // console.log("this.contentDetails in episode card", this.contentDetails);
    // console.log('this.$route.params--------',this.$route.params)
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    if (this.availabilityList !== undefined) {
      this.availabilities = this.availabilityList;
    }
    if (this.subscriptionList !== undefined) {
      this.subscriptions = this.subscriptionList;
    }

    if (this.$route.params.id) {
      this.seasonNum = this.$route.params.id;
      this.getSeasonContents();
    }
    if (this.contentDetails.seasoncount) {
      this.seasonCount = this.contentDetails.seasoncount;
    } else {
      this.getSeasonCount();
    }
    this.getLatestSeason();

    eventBus.$on("send-episode-list", (response) => {
      this.dataFetched = true;
      this.isloading = false;
      //  this.episodes = this.episodes.concat(response.data);
      if (!response.reason) {
        if (this.totalEpisodeCount == 0) {
          this.totalEpisodeCount = response.totalcount;
        }
        if (this.episodes.length < this.totalEpisodeCount && this.getEpisodePaginationInProgress) {
          let numOfNewEpisodes = response.data.length;
          this.episodes = this.episodes.concat(response.data);

          if (this.playbackInitiated == true) {
            let deltaPlaylist = this.buildPlayerPlaylist(this.episodes, this.episodes.length - numOfNewEpisodes);

            this.playerInstance.appendPlaylist(deltaPlaylist, this.totalEpisodeCount);
          }
        }
      } else {
        this.episodes = [];
        this.totalEpisodeCount = 0;
      }

      this.setEpisodePaginationInProgress(false);

      this.getAvailabilityForAllContents(this.episodes);
    });

    let deeplinkEpisodeId = this.$route.params.episodeId;
    if (deeplinkEpisodeId && this.$route.params.isPlayBackActive === "play") {
      // eventBus.$emit("startLoader");
      this.episodePlayFromRoute = true;
      this.getContent(deeplinkEpisodeId).then((res) => {
        let payload = {
          subscriptionstatus: "ALL",
        };
        store.dispatch("listSubscription", payload).then((response) => {
          if (!response.data.reason) {
            store.commit("setSubscriptionList", response.data.data);
            //this.episodes= [res.data]
            // this.episodes.unshift(res.data);
            let pageSize = this.appConfig.defaultpageSize;
            let currentPageCount = pageSize;
            let morEpisodeCall = [];
            let counter = 1;
            while (this.totalEpisodeCount > currentPageCount) {
              let pageNum = ++counter;
              currentPageCount = currentPageCount + pageSize;
              let seriesId = this.$route.params.contentId;
              let seasonNum = this.$route.params.id;
              let payload = {
                objecttype: "CONTENT",
                seriesid: seriesId,
                seasonnum: seasonNum,
                pagesize: pageSize,
                page: pageNum,
                displaylanguage: this.localDisplayLang,
              };

              morEpisodeCall.push(this.listContent(payload));
            }

            Promise.all(morEpisodeCall).then((response) => {
              if (response[0] && response[0].data && response[0].data.data) {
                this.episodes = [...this.episodes, ...response[0].data.data];
              }
              let currentIndex = 0;

              for (let index in this.episodes) {
                console.warn(this.episodes[index].objectid, deeplinkEpisodeId);

                if (this.episodes[index].objectid == deeplinkEpisodeId) {
                  currentIndex = parseInt(index);
                  break;
                }
              }
              this.loadChosenIndexContent(currentIndex, this.episodes, this.episodes.length);
            });

            // this.$router.push({name:"trailerPlaybackSeries" , params:{ isPlayBackActive: "play", trailerId: this.$route.params.episodeId}})
          }
        });
      });
    }
  },
  mounted() {
    this.episodes = [];
    this.setAudioAndSubtitleLangState(null);

    console.log("THE SCREEEN HEIGHT --> ", screen.height)

    if(screen.height >= 1440) {
      this.debounce(this.nextPageDataset, 2000);
    }

    window.addEventListener("scroll", () => {

      let scrollHeight, totalHeight;
      scrollHeight = document.body.scrollHeight;
      totalHeight = window.scrollY + window.innerHeight;

      if (totalHeight >= scrollHeight - 400) {
        this.debounce(this.nextPageDataset, 100);
      }
    });

    this.seasonNum = this.$route.params.id;
    eventBus.$on("callAvailability", () => {
      this.getAvailabilityForAllContents(this.episodes);
    });
  },
  methods: {
    ...mapMutations([
      "setRegActiveDeeplink",
      "setSubscriptionList",
      "setAudioAndSubtitleLangState",
      "setEpisodePaginationInProgress",
    ]),
    ...mapActions(["getContent", "listContent", "authorizeCurrentStreamApi", "setCurrentStreamApi"]),
    pickTagForContent(content) {
      if (content.hasOwnProperty("objecttag")) {
        let tag = content.objecttag[0];
        let contenttags = this.appConfig.contentTags;
        if (this.localDisplayLang === "ara") {
          return contenttags.ar[`${tag}`];
        } else {
          return contenttags.en[`${tag}`];
        }
      }
    },
    isObjectTag(content) {
      if (content.hasOwnProperty("objecttag")) {
        if (Array.isArray(content.objecttag)) {
          return content.objecttag.length === 0 ? false : true;
        } else {
          return content.objecttag === null ? false : true;
        }
      }
    },

    getAudioAndSubtitleLang(content) {
      const packageData = this.getFilteredPackage(content);

      const langPayload = { audiolang: packageData.audiolang, subtitlelang: packageData.subtitlelang };

      this.setAudioAndSubtitleLangState(langPayload);
    },
    nextPageDataset() {
      // console.log("========", this.contentDetails)
      // console.log(window.pageYOffset);
      if (this.episodes.length < this.totalEpisodeCount && this.isloading == false) {
        this.isloading = true;
        this.fetchEpisodeList(this.contentDetails.objectid, this.seasonNum);
      }
    },
    debounce(method, delay) {
      clearTimeout(method._tId);
      method._tId = setTimeout(function() {
        method();
      }, delay);
    },
    checkMpegRequired() {
      let isMpegRequired = false;
      let videoTag = document.createElement("video");
      let platformOs = navigator.platform;

      if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
        if (platformOs.startsWith("Mac")) {
          isMpegRequired = true;
        }
      }

      return isMpegRequired;
    },
    getAvailabilityForAllContents(val) {
      val.forEach((element) => {
        if (this.subscriberid) {
          this.getAvailability(element);
        }
        element.type = "inner";
      });
    },
    getSeasonContents() {
      this.isloading = true;
      this.episodes = [];
      this.totalEpisodeCount = 0;
      let fullPath = this.$route.fullPath.split("/");
      window.history.pushState("object or string", fullPath[fullPath.length - 1], `${this.seasonNum}`);
      //this.$router.push({name:"episodes" , params:{id:this.seasonNum}})
      this.fetchEpisodeList(this.contentDetails.objectid, this.seasonNum);
    },
    getSeasonCount() {
      //console.log("get season count");
      let payload = {
        objecttype: "SEASON",
        seriesid: this.content.objectid,
      };
      eventBus.$emit("get-seasonnum-listing", payload);
    },
    getLatestSeason() {
      for (let i = 1; i <= this.seasonCount; i++) {
        //  console.log('i--------------------',i)
        this.seasonCountArray.push(i);
      }
      this.seasonNum = this.seasonCountArray.length;
    },
    fetchEpisodeList(seriesId, seasonNum) {
      console.log("fetch episode list called from episode card-=-=-==-=-=-=--------------", seriesId, seasonNum);
      // this.isloading = true
      let pageSize = this.appConfig.defaultpageSize;
      let pageNum = Math.floor(this.episodes.length / pageSize) + 1;

      let payload = {
        objecttype: "CONTENT",
        seriesid: seriesId,
        seasonnum: seasonNum,
        pagesize: pageSize,
        page: pageNum,
        displaylanguage: this.localDisplayLang,
      };

      if (this.isMpegRequired == true) {
        payload.mpegtspackage = "YES";
      }

      this.episodePaginatinInProgress = true;
      eventBus.$emit("get-episode-list", payload);
    },
    handleScrollEvent(event) {
      // console.log("THE SCROLL EVENT ------->");
      // console.log('episode length',this.episodes.length);
      // console.log('total episode count',this.totalEpisodeCount);
    },
    buildContentStreamRequest(contentItem, isMpegRequired) {
      let payload;
      if (contentItem.hasOwnProperty("playback_details")) {
        payload = {
          contentid: contentItem.objectid,
          params: {
            availabilityid: contentItem.playback_details.availabilityid,
            packageid: contentItem.playback_details.packageid,
          },
        };

        if (isMpegRequired == true) {
          payload.params.mpegtspackage = "YES";
        }
      } else {
        this.hidePlayer();
        this.setRegActiveDeeplink(true);
					setTimeout(() => {
				document.exitFullscreen()
				.then(() => console.log("Document Exited from Full screen mode"))
				.catch((err) => console.error(err))
				},10)

				let adminProfileDetails = localStorage.getItem('subscriberDetails') ? JSON.parse(localStorage.getItem('subscriberDetails')).data : {};
      console.error("this.subscriberDetails======", this.subscriberDetails)
      let profileSubUser = localStorage.getItem('subscriberProfileDetails') ? JSON.parse(localStorage.getItem('subscriberProfileDetails')) : {}; 
				if (this.subscriberid && this.subscriptions.length === 0 && adminProfileDetails.subscriberid != profileSubUser.profileid) {


            eventBus.$emit("showAuthorizeErrorMessage", {
              state: true,
              messageData: {
                title: "Error",
                message: this.$t("Sorry! Admin only allowed to the payment. Please contact your Admin"),
              },
            });

            return false;
          } else  if(adminProfileDetails.subscriberid == profileSubUser.profileid) {
						   // Display Subscriptions popup.
        		let payload = { listType: "onlySubscribe" };
        		eventBus.$emit("subscribePopup", payload);
					}

     
      }

      return payload;
    },
    fetchContentStreamUrl(contentItem, isMpegRequired) {
      return new Promise((resolve, reject) => {
        if (contentItem.streamUrl != undefined && contentItem.streamUrl != "") {
          resolve(true);
        } else {
          resolve(store.dispatch("contentStreamUrl", this.buildContentStreamRequest(contentItem, isMpegRequired)));
        }
      });
    },
    fetchContentDetails(contentItem, isMpegRequired) {
      // console.log("Content item : ", contentItem);
      return new Promise((resolve, reject) => {
        if (contentItem.contentdetails) {
          resolve(true);
        } else {
          let requestData = {};
          let queryParams = {};

          queryParams.displaylanguage = this.localDisplayLang;

          if (isMpegRequired == true) {
            queryParams.mpegtspackage = "YES";
          }

          requestData.contentid = contentItem.objectid;

          requestData.params = queryParams;

          resolve(store.dispatch("contentDetail", requestData));
        }
      });
    },
    handleFetchContentStreamUrl(response, contentItem) {
      contentItem.streamUrl = response.data.streamfilename;
			contentItem.scrubbing = response.data.packagedfilelist && response.data.packagedfilelist.scrubbing ? response.data.packagedfilelist.scrubbing : '';
    },

    getAvailability(contentItem) {
      // console.log('INSIDE GET-AVAILABILITY=======',contentItem)
      if (contentItem.hasOwnProperty("contentdetails") && contentItem.contentdetails.length > 0) {
        contentItem.isContentDetail = true;
        let packageDetails = this.getFilteredPackage(contentItem);

        console.log("THIS IS THE EPISODE AVAIL --- ", packageDetails);

        let availPlanList = this.getAvailabilityAndPlanDetails(packageDetails, this.availabilities);
        let filteredAvailabilityList = availPlanList.filteredAvailabilities;

        filteredAvailabilityList.every((availability) => {
          if (availability.pricemodel === "PLAN") {
            if (this.subscriberid && this.subscriptions.length > 0) {
              this.subscriptions.every((subscription) => {
                // Check if next billing date is greater than today's date.
                if (this.getDateForPlayback(subscription.nextbilling) < this.getTodaysDate()) return;

                let value = subscription.availabilityset.includes(availability.availabilityid);

                if (value) {
                  contentItem.isDrmContent = true;
                  contentItem.videoType = "Content";
                  //creating this playback_details for content playback.
                  contentItem.playback_details = {
                    packageid: packageDetails.packageid,
                    availabilityid: availability.availabilityid,
                    drmscheme: packageDetails.drmscheme[0],
                  };

                  return false;
                }
              });
            } else if (this.subscriberid && this.subscriptions.length === 0) {
              // console.log(
              //   "content price model is not free and user has to subscribe to the plan to watch this content"
              // );
            }
          } else if (availability.pricemodel == "FREE") {
            contentItem.isDrmContent = true;
            contentItem.videoType = "Content";
            //creating this playback_details for content playback.
            contentItem.playback_details = {
              packageid: packageDetails.packageid,
              availabilityid: availability.availabilityid,
              drmscheme: packageDetails.drmscheme[0],
            };
            return false;
          }
        });
      } else if (!contentItem.hasOwnProperty("contentdetails")) {
        contentItem.isContentDetail = false;
      }
    },

    getAvailabilityAndPlanDetails(packageDetails, availabilitiesList) {
      //console.log("availabilityset check=========================",packageDetails)
      let priceAndAvailabilityData = {};
      priceAndAvailabilityData.pricemodel = [];
      priceAndAvailabilityData.filteredAvailabilities = [];

      packageDetails.availabilityset.forEach((availability) => {
        let availabilityIndex = availabilitiesList.findIndex((element) => {
          return element.availabilityid === availability;
        });

        if (availabilityIndex > -1) {
          priceAndAvailabilityData.pricemodel.push(availabilitiesList[availabilityIndex].pricemodel);
          priceAndAvailabilityData.filteredAvailabilities.push(availabilitiesList[availabilityIndex]);
        }
      });

      return priceAndAvailabilityData;
    },

    getFilteredPackage(contentItem) {
      //console.log('content item in get filtered package--------',contentItem)
      let temporaryVideoTag = document.createElement("video");
      let hasNativeHlsSupport = temporaryVideoTag.canPlayType("application/vnd.apple.mpegurl");

      let isAndroid = navigator.userAgent.includes("Android");

      let packageList = [];
      // console.log('availabilities-------------------',this.availabilities)
      //pick content details if that availability id is there in availability list.
      this.availabilities.forEach((availability) => {
        contentItem.contentdetails.forEach((element) => {
          if (element.availabilityset.includes(availability.availabilityid)) {
            packageList.push(element);
          }
        });
      });
      // console.log('package list---------------------',packageList)
      let packageIndex = packageList.findIndex((contentPackage) => {
        if (!isAndroid && hasNativeHlsSupport != "") {
          return contentPackage.drmscheme[0] === "FAIRPLAY";
        } else {
          return contentPackage.drmscheme[0] === "WIDEVINE";
        }
      });
      //console.log('package INdex---------------------',packageIndex)
      if (packageIndex == -1) {
        return packageList[
          packageList.findIndex((contentPackage) => {
            return packageList.drmscheme[0] === "NONE";
          })
        ];
      } else if (packageIndex > -1) {
        return packageList[packageIndex];
      }
    },

    loadChosenIndexContent(episodeIndex, episodeList, totalCount, isFirstTime = true) {
      this.fetchContentDetails(episodeList[episodeIndex], this.isMpegRequired)
        .then((resp) => {
          if (typeof resp == "object" && !resp.data.errorcode) {
            episodeList[episodeIndex].title = resp.data.title;
            episodeList[episodeIndex].contentdetails = resp.data.contentdetails;
            episodeList[episodeIndex].seriesname = resp.data.seriesname;
          } else if (typeof resp == "boolean" && resp == true) {
            // console.log("Content detail present");
          } else {
            let playbackError = true;

            if (typeof resp == "object") {
              playbackError = resp.data;
            }
            throw playbackError;
          }

          this.fetchContentStreamUrl(episodeList[episodeIndex], this.isMpegRequired).then((resp) => {
            let loadPlayer = false;
            this.showPlayer();

            if (this.appConfig.featureEnabled.isConcurrencyEnabled) {
              // Call the Stream Authorize API
              this.authorizeCurrentStreamApi().then((authResponse) => {
                console.log("authResponse - From Authorize API --- EPISODE CARD --- > ", authResponse);

                if (!authResponse.data.errorcode) {
                  if (typeof resp == "object" && !resp.data.errorcode) {
                    this.handleFetchContentStreamUrl(resp, episodeList[episodeIndex]);
                    loadPlayer = true;
                  } else if (typeof resp == "boolean" && resp == true) {
                    loadPlayer = true;
                  }
                  if (loadPlayer == true) {
                    this.loadContentMetaData(episodeList[episodeIndex], null);

                    if (typeof resp == "object") {
                      if (isFirstTime == true) {
                        let playlist = this.buildPlayerPlaylist(episodeList, 0);
                        this.playerInstance.loadPlaylist(playlist, totalCount);
                      } else {
                        let playlist = this.playerInstance.playlist;
                        let updatedContentItem = this.createPlayerPlaylistItem(episodeList[episodeIndex], 0);
                        playlist.updateItem(episodeIndex, updatedContentItem);
                      }
                    }

                    this.playerInstance.loadContent(episodeIndex).then(() => {
                      this.playerInstance.play().then(() => {
                        //eventBus.$emit("endLoader")
                        console.log("Playback successful in episode card through deeplink====");

                        const payload = {
                          contentId: episodeList[episodeIndex].objectid,
                          params: {
                            devicetype: "PC",
                          },
                        };

                        this.setCurrentStreamApi(payload).then((setStreamResponse) => {
                          console.log("THE SET STREAM API RESP === > ", setStreamResponse);
                        });
                      });
                    });
                  } else {
                    let playbackError = true;

                    this.hidePlayer();

                    eventBus.$emit("showAuthorizeErrorMessage", {
                      state: true,
                      messageData: { title: this.$t("Error"), message: this.$t("Device Limit Reached") },
                    });

                    if (typeof resp == "object") {
                      playbackError = resp.data;
                    }
                    throw playbackError;
                  }
                } else {
                  let playbackError = true;

                  this.hidePlayer();

                  eventBus.$emit("showAuthorizeErrorMessage", {
                    state: true,
                    messageData: { title: this.$t("Error"), message: this.$t("Device Limit Reached") },
                  });

                  if (typeof resp == "object") {
                    playbackError = resp.data;
                  }
                  throw playbackError;
                }
              });
            }
            // If concurrency is disabled form the Config
            else {
              if (typeof resp == "object" && !resp.data.errorcode) {
                this.handleFetchContentStreamUrl(resp, episodeList[episodeIndex]);
                loadPlayer = true;
              } else if (typeof resp == "boolean" && resp == true) {
                loadPlayer = true;
              }
              if (loadPlayer == true) {
                this.loadContentMetaData(episodeList[episodeIndex], null);

                if (typeof resp == "object") {
                  if (isFirstTime == true) {
                    let playlist = this.buildPlayerPlaylist(episodeList, 0);
                    this.playerInstance.loadPlaylist(playlist, totalCount);
                  } else {
                    let playlist = this.playerInstance.playlist;
                    let updatedContentItem = this.createPlayerPlaylistItem(episodeList[episodeIndex], 0);
                    playlist.updateItem(episodeIndex, updatedContentItem);
                  }
                }

                this.playerInstance.loadContent(episodeIndex).then(() => {
                  this.playerInstance.play().then(() => {
                    console.log("Playback successful");
                  });
                });
              } else {
                let playbackError = true;

                this.hidePlayer();

                if (typeof resp == "object") {
                  playbackError = resp.data;
                }
                throw playbackError;
              }
            }
          });
        })
        .catch((error) => {
          console.log("Content playback failed : ", error);
          this.hidePlayer();
        });
    },

    loadChosenContentData(chosenContentIndex) {
      this.playerInstance.unloadPlayer();
      let episodeIndex = chosenContentIndex;

      this.loadChosenIndexContent(episodeIndex, this.episodes, this.totalEpisodeCount, false);
    },

    loadMoreContentData() {
      let episodeIndex = this.episodes.length;

      this.fetchEpisodeList(this.contentDetails.objectid, this.seasonNum).then((response) => {
        if (!response.data.errorcode) {
          let episodeList = response.data.data;
          this.episodes = this.episodes.concat(episodeList);

          let deltaPlaylist = this.buildPlayerPlaylist(this.episodes, episodeIndex);

          this.playerInstance.appendPlaylist(deltaPlaylist, this.totalEpisodeCount);
        }
      });
    },

    loadNextContentData(endedContentIndex) {
      let episodeIndex = endedContentIndex + 1;
      console.log("Content playback ended : ", endedContentIndex);
      let nextepisodeid = this.episodes[endedContentIndex + 1] ? this.episodes[endedContentIndex + 1].objectid : "";
      console.log("LOAD NEXT CONTENT DATA == NEXT EPISODE ID IS", nextepisodeid);
      localStorage.setItem("nextepisodeid", nextepisodeid);
      if (episodeIndex < this.episodes.length) {
        this.loadChosenIndexContent(episodeIndex, this.episodes, this.totalEpisodeCount, false);
      } else {
        this.hidePlayer();
      }
    },

    showPlayer() {
      try {
        this.playerInstance.blowUpPlayer();
        this.playerInstance.registerEvent("currentcontentended", this.loadNextContentData);
        this.playerInstance.registerEvent("loadchosencontent", this.loadChosenContentData);
        this.playerInstance.registerEvent("loadmorecontent", this.loadMoreContentData);
        this.playbackInitiated = true;
        eventBus.$emit("player-visibility", true);
      } catch (error) {
        console.log("ERROR====", error);
      }
    },

    playContent(content, contentIndex) {
      let nextepisodeid = this.episodes[contentIndex + 1] ? this.episodes[contentIndex + 1].objectid : "";
      console.log("PLAY CONTENT == NEXT EPISODE ID IS", nextepisodeid);
      localStorage.setItem("nextepisodeid", nextepisodeid);
      this.episodePlayFromRoute = false;
      let fullPath = this.$route.fullPath.split("/");
      //  console.log('what is the content',content)
      //  console.log('subscriber id',this.subscriberid);
      //  console.log('episode is content detail',this.episodes[contentIndex].isContentDetail);
      //  console.log('has own property',    this.episodes[contentIndex].hasOwnProperty("playback_details"))
      if (
        this.subscriberid &&
        this.episodes[contentIndex].isContentDetail &&
        this.episodes[contentIndex].hasOwnProperty("playback_details")
      ) {
        this.showPlayer();

        // let seriesContentNode = null;
        //
        // if (content.category !== 'MOVIE') {
        //     seriesContentNode = this.content;
        //     this.playlistIndex = contentIndex;
        // }
        if (content.category == "MOVIE") {
          this.episodes = [content];
          this.loadChosenIndexContent(contentIndex, this.episodes, this.totalEpisodeCount, true);
        } else {
          if (!fullPath.includes("episode")) {
            window.history.pushState(
              "object or string",
              {},
              this.$route.params.id + "/episode/" + content.objectid + "/play"
            );
          } else {
            const url = new URL(window.location);
            console.log("url is-----", url);
            let pathname = url.pathname.split("/");
            console.log("pathname", pathname);
            let finalUrl = `${window.location.origin}/series/${pathname[2]}/${pathname[3]}/${pathname[4]}/${pathname[5]}/episode/${content.objectid}/play`;
            window.history.pushState("object or string", {}, finalUrl);
          }

          this.loadChosenIndexContent(contentIndex, this.episodes, this.totalEpisodeCount, true);
        }
      } else if (
        this.subscriberid &&
        content.isContentDetail &&
        (this.subscriptions.length === 0 || this.subscriptions.length > 0) &&
        this.subscriberid !== this.profileid
      ) {
        console.log("Error message block entered");

        eventBus.$emit("showAuthorizeErrorMessage", {
          state: true,
          messageData: {
            title: "Error",
            message: this.$t("Sorry! Admin only allowed to the payment. Please contact your Admin"),
          },
        });
      }

      // User is logged in and has no subscription -- Master Profile
      else if (
        this.subscriberid &&
        content.isContentDetail &&
        (this.subscriptions.length === 0 || this.subscriptions.length > 0)
      ) {
        this.setRegActiveDeeplink(true);

        //show subscribe popup.
        let payload = { listType: "onlySubscribe" };
        localStorage.setItem("payment_source", "Content Details");

        eventBus.$emit("subscribePopup", payload);
      } else if (this.subscriberid && !content.isContentDetail) {
        //show snackbar component.
        eventBus.$emit("replace-content", content);
      } else {
        //open lookup popup.
        let payload = {
          state: true,
          formType: "lookup",
        };
        eventBus.$emit("authPopup", payload);
      }
    },

    buildPlayerPlaylist(episodeList, startIndex) {
      let playlist = [];
      for (var aIndex = startIndex; aIndex < episodeList.length; aIndex++) {
        let playlistItem = this.createPlayerPlaylistItem(episodeList[aIndex], 0);
        playlist.push(playlistItem);
      }

      return playlist;
    },

    loadContentMetaData(contentMetaData, seriesMetaData) {
      let metaData = {
        content: contentMetaData,
        seriesContent: seriesMetaData,
      };

      eventBus.$emit("load-player-content", metaData);
    },

    hidePlayer() {
      this.playerInstance.shrinkPlayer();
      eventBus.$emit("player-visibility", false);

      let payload = { content: null, seriesContent: null };
      eventBus.$emit("load-player-content", payload);

      this.playerInstance.unloadPlayer(true);
    },

    extractThumbnailPoster(contentItem) {
      let posterKey = contentItem.poster;
      let thumbNailUrl = null;

      if (posterKey && Array.isArray(posterKey) == true && posterKey.length > 0) {
        // let posterFileList = posterKey[0].filelist;

        let posterFileList = posterKey.find((posterKeyNode) => posterKeyNode.postertype == "LANDSCAPE").filelist;

        if (posterFileList && Array.isArray(posterFileList) == true && posterFileList.length > 0) {
          let thumbNailNode = posterFileList.find((posterNode) => posterNode.quality == "THUMBNAIL");

          if (thumbNailNode) {
            thumbNailUrl = thumbNailNode.filename;
          }
        }
      }

      return thumbNailUrl;
    },

    createPlayerPlaylistItem(content) {
      let playlistItem = new vLivePlaylistItem({
        contentId: content.objectid,
        contenttype: content.category,
        title: content.title,
        description: content.shortdescription,
        thumbnail: this.extractThumbnailPoster(content),
        source: content.streamUrl != undefined && content.streamUrl != "" ? [content.streamUrl] : [],
        licenseServer: this.appConfig.providerDetails.drmLicenseUrl,
        packageid: content.playback_details ? content.playback_details.packageid : "",
        providerid: "noorplay",
        drmscheme: content.playback_details ? content.playback_details.drmscheme : "",
        availabilityid: content.playback_details ? content.playback_details.availabilityid : "",
        providerSession: localStorage.getItem("sessionToken"),
        skip: content.skip && Array.isArray(content.skip) && content.skip.length > 0 ? content.skip : "",
        isDrmContent: content.isDrmContent,
        skip: content.skip && Array.isArray(content.skip) && content.skip.length > 0 ? content.skip : "",
        position: 0,
				scrubbing: content.scrubbing ?content.scrubbing[0] : '',
      });

      return playlistItem;
    },
  },
  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },
  beforeDestroy() {
    eventBus.$off("send-episode-list");
    // eventBus.$off("remove-episode-added-from-route");
    eventBus.$off("callAvailability");
    //  eventBus.$off("endLoader")
    //    eventBus.$off("startLoader")
  },
  mixins: [Utility],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
.mainLayout {
  margin-top: 24px;
  .mainLayoutSeason {
    margin-right: 40px;
    .select-ara {
      border: 1px solid $clr-light-gd1;
      width: 6%;
      padding: 0.4rem;
      margin: 0;
      margin-left: 0px;
      background: url("../../assets/icons/down_arrow.svg") no-repeat;
      -webkit-appearance: none;
      background-size: 12px;
      background-position-y: 54%;
      cursor: pointer;
      option {
        color: white;
      }
      #season-option {
        background: #161616;
      }
      &:focus {
        background-color: transparent;
      }
      background-position-x: 3%;
    }
    .select-eng {
      border: 1px solid $clr-light-gd1;
      width: 6rem;
      padding: 0.4rem;
      margin: 0;
      margin-left: 0px;
      background: url("../../assets/icons/down_arrow.svg") no-repeat;
      -webkit-appearance: none;
      background-size: 12px;
      background-position-y: 54%;
      cursor: pointer;
      option {
        color: white;
      }
      #season-option {
        background: #161616;
      }
      &:focus {
        background-color: transparent;
      }

      background-position-x: 92%;
    }
  }
  // @media only screen and (max-width: 576px) {
  //   .main-layout {
  //     .main-layout-season {
  //       margin-right: 10px;
  //       .select-eng {
  //         width: 38%;
  //       }
  //       .select-ara {
  //         width: 24%;
  //       }
  //     }
  //   }
  // }

  // @media only screen and (min-width: 577px) and (max-width: 768px) {
  //   .main-layout {
  //     .main-layout-season {
  //       margin-right: 20px;
  //       .select-eng {
  //         width: 13%;
  //       }
  //       .select-ara {
  //         width: 13%;
  //       }
  //     }
  //   }
  // }
  .episodeCards {
    margin-top: 18px;
    width: 100%;
    // max-width: 1200px;
    // margin-right: 40px;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    // grid-column-gap: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 36px;

    .episodeCard {
      border-radius: 4px;
      margin-right: 55px;
      cursor: pointer;
      .episodeContainer {
        width: 250px;
        height: 190px;
        position: relative;
        transition: transform 450ms;
      }
      .episodeContainer:hover {
        z-index: 10;
        transform: scale(1.2);
        .episodeContentLayout {
          height: auto;
        }
        .episodeDescription {
          display: block;
          color: #ffffff;
          font-size: 0.8rem;
          opacity: 0.6;
          // text-align: left;
          line-height: 15px;
          font-family: $font-regular;
          margin-bottom: 10px;
          line-height: 1.2rem;
        }
      }
      .episodePosterLayout {
        width: auto;
        position: absolute;
        /* for demo purpose  */
        .episodePoster {
          width: auto;
          height: 165px;
          border-radius: 4px;
        }
      }
      .episodeTag {
        position: absolute;
        top: 2%;
        left: 0%;
        color: #fff;
        background: $btn-clr-new;
        font-family: $font-regular;
        padding: 5px 10px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        .tag {
          font-size: 0.7rem;
        }
      }
      .episodeTagRight {
        position: absolute;
        top: 2%;
        right: 0%;
        color: #fff;
        background: $btn-clr-new;
        font-family: $font-regular;
        padding: 5px 10px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        .tag {
          font-size: 0.8rem;
        }
      }
      .play-img {
        width: 294px;
        height: 20%;
        position: absolute;
        top: 25%;
        opacity: 0.8;
        z-index: 9;
      }
      .episodeContentLayout {
        width: 294px;
        height: 20%;
        position: absolute;
        top: 68%;
        opacity: 1;
        z-index: 9;
        margin: 0px; /* for demo purpose  */
        background-color: rgb(31, 30, 30);

        .episodeContentSplitter {
          display: flex;
          padding: 10px;
          justify-content: space-between;

          .episodeTitle {
            color: #ffffff;
            font-family: $font-regular;
            font-size: 1rem;
            font-weight: 600;
          }
          .episodeDuration {
            color: #ffffff;
            font-family: $font-regular;
            font-size: 0.8rem;
            opacity: 0.8;
          }
        }
        .episodeDescription {
          padding: 0px;
          margin-left: 10px;
          margin-right: 10px;
          display: none;
          color: #ffffff;
          font-size: 0.8rem;
          opacity: 0.6;
          font-family: $font-regular;
        }
      }
    }
  }
  .episodeCardsAra {
    margin-top: 18px;
    width: 100%;
    // max-width: 1200px;
    // margin-right: 40px;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    // grid-column-gap: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 36px;
    margin-right: 40px;
    .episodeCard {
      border-radius: 4px;
      margin-left: 55px;
      cursor: pointer;
      .episodeContainer {
        width: 250px;
        height: 190px;
        position: relative;
        transition: transform 450ms;
      }
      .episodeContainer:hover {
        z-index: 10;
        transform: scale(1.2);
        .episodeContentLayout {
          height: auto;
        }
        .episodeDescription {
          display: block;
          color: #ffffff;
          font-size: 0.8rem;
          opacity: 0.6;
          text-align: right;
          line-height: 15px;
          font-family: $font-regular;
          margin-bottom: 10px;
          line-height: 1.2rem;
        }
      }
      .episodePosterLayout {
        width: auto;
        position: absolute;
        /* for demo purpose  */
        .episodePoster {
          width: auto;
          height: 165px;
          border-radius: 4px;
        }
      }
      .episodeTag {
        position: absolute;
        top: 2%;
        left: 0%;
        color: #fff;
        background: $btn-clr-new;
        font-family: $font-regular;
        padding: 5px 10px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        .tag {
          font-size: 0.7rem;
        }
      }
      .episodeTagRight {
        position: absolute;
        top: 2%;
        right: 0%;
        color: #fff;
        background: $btn-clr-new;
        font-family: $font-regular;
        padding: 5px 10px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        .tag {
          font-size: 0.8rem;
        }
      }
      .play-img {
        width: 294px;
        height: 20%;
        position: absolute;
        top: 25%;
        opacity: 0.8;
        z-index: 9;
      }
      .episodeContentLayout {
        width: 294px;
        height: 20%;
        position: absolute;
        top: 68%;
        opacity: 1;
        z-index: 9;
        margin: 0px; /* for demo purpose  */
        background-color: rgb(31, 30, 30);

        .episodeContentSplitter {
          display: flex;
          padding: 10px;
          justify-content: space-between;

          .episodeTitle {
            color: #ffffff;
            font-family: $font-regular;
            font-size: 1rem;
          }
          .episodeDuration {
            color: #ffffff;
            font-family: $font-regular;
            font-size: 0.8rem;
            opacity: 0.8;
          }
        }
        .episodeDescription {
          padding: 0px;
          margin-left: 10px;
          margin-right: 10px;
          display: none;
          color: #ffffff;
          font-size: 0.8rem;
          opacity: 0.6;
          font-family: $font-regular;
        }
      }
    }
  }
}

// @media only screen  and (max-width: 399px) {
//  .mainLayout {
//     margin-right: 0px;
//     .mainLayoutSeason {
//       margin-right: 20px;
//       .select-eng {
//         width: 32%;
//       }
//       .select-ara {
//         width: 24%;
//       }
//     }
//     .episodeCards {
//       grid-template-columns: 1fr;
//       margin-right: 20px;
//       .episodeCard {
//         width: 320px;
//         height: 15rem;
//         .episodePosterLayout {
//           width: 320px;
//           position: absolute;
//           /* for demo purpose  */
//           .episodePoster {
//             max-width: 320px;
//             height: auto;
//             border-radius: 4px;
//           }
//         }
//         // .episodeContainer{
//         //   .episodeContentLayout {
//         //   height: auto;
//         // }
//         //   .episodeDescription {
//         //   height: auto;
//         //   display: block;
//         //   color: #ffffff;
//         //   font-size: 12px;
//         //   opacity: 80%;
//         //   text-align: justify;
//         //   line-height: 15px;
//         //   font-family: $font-regular;
//         //   margin-bottom: 10px;
//         //   line-height: 18px;
//         // }
//         // }
//         .play-img {
//           width: 320px;
//           height: 20%;
//           position: absolute;
//           top: 30%;
//           opacity: 0.8;
//           z-index: 9;
//         }
//         .episodeContentLayout {
//           margin-top: 50px;
//           width: 320px;
//         }
//       }
//     }
//   }
// }

@media only screen and (min-width: 300px) and (max-width: 576px) {
  .mainLayout {
    margin-right: 0px;
    margin-left: 0px;
    .mainLayoutSeason {
      margin-right: 20px;
      .select-eng {
        width: 32%;
      }
      .select-ara {
        width: 24%;
      }
    }
    .episodeCards {
      .episodeCard {
        margin-top: 40px;
        margin-right: 20px;
        .episodePosterLayout {
          width: auto;
          position: absolute;
          /* for demo purpose  */
          .episodePoster {
            width: auto;
            height: 178px;
            border-radius: 4px;
          }
        }
        .play-img {
          width: 316px;
          height: 20%;
          position: absolute;
          top: 30%;
          opacity: 0.8;
          z-index: 9;
        }
        .episodeContentLayout {
          margin-top: 45px;
          width: 316px;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: portrait) {
  .mainLayout {
    margin-right: 0px;
    margin-left: 0px;
    .mainLayoutSeason {
      margin-right: 20px;
      margin-left: 0px;
      .select-eng {
        width: 13%;
      }
      .select-ara {
        width: 12.5%;
        // width: 125px;
      }
    }
    .episodeCards {
      .episodeCard {
        margin-right: 110px;
        margin-top: 20px;
        .episodePosterLayout {
          width: auto;
          position: absolute;
          /* for demo purpose  */
          .episodePoster {
            width: auto;
            height: 190px;
            border-radius: 4px;
          }
        }
        .play-img {
          width: 340px;
        }

        .episodeContentLayout {
          top: 80%;
          width: 340px;
        }
      }
    }
    .episodeCardsAra {
      margin-right: 20px;
      .episodeCard {
        margin-right: 0px;
        margin-left: 110px;
        margin-top: 20px;
        .episodePosterLayout {
          width: auto;
          position: absolute;
          /* for demo purpose  */
          .episodePoster {
            width: auto;
            height: 190px;
            border-radius: 4px;
          }
        }
        .play-img {
          width: 340px;
        }

        .episodeContentLayout {
          top: 80%;
          width: 340px;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
  .mainLayout {
    margin-right: 0px;
    margin-left: 0px;
    .mainLayoutSeason {
      margin-right: 20px;
      margin-left: 0px;
      .select-eng {
        width: 13%;
      }
      .select-ara {
        width: 10%;
        // width: 125px;
      }
    }
    .episodeCards {
      .episodeCard {
        margin-right: 70px;
        margin-top: 20px;
        .episodePosterLayout {
          width: auto;
          position: absolute;
          /* for demo purpose  */
          .episodePoster {
            width: auto;
            height: 170px;
            border-radius: 4px;
          }
        }
        .play-img {
          width: 304px;
        }

        .episodeContentLayout {
          top: 80%;
          width: 304px;
        }
      }
    }
    .episodeCardsAra {
      margin-right: 20px;
      .episodeCard {
        margin-left: 70px;
        margin-top: 20px;
        .episodePosterLayout {
          width: auto;
          position: absolute;
          /* for demo purpose  */
          .episodePoster {
            width: auto;
            height: 170px;
            border-radius: 4px;
          }
        }
        .play-img {
          width: 304px;
        }

        .episodeContentLayout {
          top: 80%;
          width: 304px;
        }
      }
    }
  }
}

@media (min-width: 1400px) and (max-width: 1679px) {
  .mainLayout {
    .episodeCards {
      .episodeCard {
        margin-right: 25px;
        margin-top: 20px;
        .episodePosterLayout {
          width: auto;
          position: absolute;
          /* for demo purpose  */
          .episodePoster {
            width: auto;
            height: 140px;
            border-radius: 4px;
          }
        }
        .play-img {
          width: 260px;
        }

        .episodeContentLayout {
          top: 70%;
          width: 250px;
        }
      }
    }
    .episodeCardsAra {
      .episodeCard {
        margin-left: 25px;
        margin-top: 20px;
        .episodePosterLayout {
          width: auto;
          position: absolute;
          /* for demo purpose  */
          .episodePoster {
            width: auto;
            height: 140px;
            border-radius: 4px;
          }
        }
        .play-img {
          width: 260px;
        }

        .episodeContentLayout {
          top: 70%;
          width: 250px;
        }
      }
    }
  }
}

@media (min-width: 1680px) and (max-width: 1919px) {
  .mainLayout {
    .episodeCards {
      .episodeCard {
        margin-right: 70px;
        margin-top: 10px;
        .episodePosterLayout {
          width: auto;
          position: absolute;
          /* for demo purpose  */
          .episodePoster {
            width: auto;
            height: 170px;
            border-radius: 4px;
          }
        }
        .play-img {
          width: 305px;
        }

        .episodeContentLayout {
          top: 70%;
          width: 305px;
        }
      }
    }
    .episodeCardsAra {
      .episodeCard {
        margin-left: 70px;
        margin-top: 10px;
        .episodePosterLayout {
          width: auto;
          position: absolute;
          /* for demo purpose  */
          .episodePoster {
            width: auto;
            height: 170px;
            border-radius: 4px;
          }
        }
        .play-img {
          width: 305px;
        }

        .episodeContentLayout {
          top: 70%;
          width: 305px;
        }
      }
    }
  }
}
</style>
